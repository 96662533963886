import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {ControlPumpModule} from './control-pump/control-pump.module';
import {ManagePumpPageModule} from './manage-pump/manage-pump.module';
import {LoginPageModule} from './login/login.module';
import {MainMenuPageModule} from './main-menu/main-menu.module';
import {ConfigPumpComponent} from './config-pump/config-pump.component';

import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {httpInterceptorProviders} from './http-interceptor';
import {IonicStorageModule} from '@ionic/storage';

@NgModule({
    declarations: [AppComponent, ConfigPumpComponent],
    entryComponents: [ConfigPumpComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        ControlPumpModule,
        ManagePumpPageModule,
        LoginPageModule,
        MainMenuPageModule,
        FormsModule,
        HttpClientModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        httpInterceptorProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
