import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    constructor(private ionicStorage: Storage) {
    }

    public async remove(key: string): Promise<void> {
        localStorage.removeItem(key);
        try {
            await this.ionicStorage.remove(key);
        } catch (error) {
            return;
        }
    }

    public async set(key: string, value: any): Promise<void> {
        localStorage.setItem(key, JSON.stringify(value));
        try {
            await this.ionicStorage.set(key, value);
        } catch (error) {
            return;
        }
    }

    public async get(key: string): Promise<any> {
        const value = await this.ionicStorage.get(key);
        return value || JSON.parse(localStorage.getItem(key));
    }
}