import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-config-pump',
    templateUrl: './config-pump.component.html',
    styleUrls: ['./config-pump.component.scss']
})
export class ConfigPumpComponent implements OnInit {

    @Input() timerValue;
    @Input() deviceName;
    savedTimeValue = 0;

    constructor(private modalController: ModalController) {}

    ngOnInit() {
        this.savedTimeValue = this.timerValue;
    }

    closeModel() {
        this.modalController.dismiss(this.savedTimeValue);
    }

    saveTimer() {
        this.savedTimeValue = this.timerValue;
        this.closeModel();
    }

}
