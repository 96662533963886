import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ShareService {
  public forceFullscreen = false;
  public loading = false;
  public userLoadedHandle: any = null;

  constructor() {}
}
