import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    constructor(private http: HttpClient) {
    }

    public login(user): Promise<any> {
        const loginRequest = {
            username: user.username,
            password: user.password
        };

        return this.http.post('https://connexthings.io/api/auth/login', loginRequest).toPromise();
    }

    public changePassword(currentPassword, newPassword) {
        const url = 'https://connexthings.io/api/auth/changePassword';
        return this.http.post(url, {currentPassword: currentPassword, newPassword: newPassword}, {responseType: 'text'}).toPromise();
    }
}
