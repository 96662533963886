import {Injectable} from '@angular/core';
import {$WebSocket} from 'angular2-websocket/angular2-websocket';
import {UserService} from './user.service';
import {TypesConstant} from '../common/types.constant';

const RECONNECT_INTERVAL = 2000;
const WS_IDLE_TIMEOUT = 90000;

const MAX_PUBLISH_COMMANDS = 10;

@Injectable({
    providedIn: 'root'
})
export class TelemetryWebsocketService {
    private isOpening = false;
    private isOpened = false;
    private isActive = false;
    private isReconnect = false;
    private reconnectSubscribers = [];
    private telemetryUri: string;
    private socketCloseTimer;
    private lastCmdId = 0;
    private subscribers = {};
    private subscribersCount = 0;
    private commands = {};
    private cmdsWrapper = {
        tsSubCmds: [],
        historyCmds: [],
        attrSubCmds: []
    };
    private reconnectTimer;

    private dataStream;


    constructor(private userService: UserService) {

        this.telemetryUri = 'wss://connexthings.io';
        this.telemetryUri += '/api/ws/plugins/telemetry';

        this.userService.on('unauthenticated').subscribe(doLogout => {
            if (doLogout) {
                this.reset(true);
            }
        });
    }

    private closeSocket() {
        this.isActive = false;
        if (this.isOpened) {
            this.dataStream.close();
        }
    }

    private reset(close) {
        if (this.socketCloseTimer) {
            clearTimeout(this.socketCloseTimer);
            this.socketCloseTimer = null;
        }
        this.lastCmdId = 0;
        this.subscribers = {};
        this.subscribersCount = 0;
        this.commands = {};
        this.cmdsWrapper.tsSubCmds = [];
        this.cmdsWrapper.historyCmds = [];
        this.cmdsWrapper.attrSubCmds = [];
        if (close) {
            this.closeSocket();
        }
    }

    private hasCommands() {
        return this.cmdsWrapper.tsSubCmds.length > 0 ||
            this.cmdsWrapper.historyCmds.length > 0 ||
            this.cmdsWrapper.attrSubCmds.length > 0;
    }

    private popCmds(cmds, leftCount) {
        const toPublish = Math.min(cmds.length, leftCount);
        if (toPublish > 0) {
            return cmds.splice(0, toPublish);
        } else {
            return [];
        }
    }

    private preparePublishCommands() {
        const preparedWrapper: any = {};
        let leftCount = MAX_PUBLISH_COMMANDS;
        preparedWrapper.tsSubCmds = this.popCmds(this.cmdsWrapper.tsSubCmds, leftCount);
        leftCount -= preparedWrapper.tsSubCmds.length;
        preparedWrapper.historyCmds = this.popCmds(this.cmdsWrapper.historyCmds, leftCount);
        leftCount -= preparedWrapper.historyCmds.length;
        preparedWrapper.attrSubCmds = this.popCmds(this.cmdsWrapper.attrSubCmds, leftCount);
        return preparedWrapper;
    }

    private checkToClose() {
        if (this.subscribersCount === 0 && this.isOpened) {
            if (!this.socketCloseTimer) {
                this.socketCloseTimer = setTimeout(this.closeSocket, WS_IDLE_TIMEOUT, false);
            }
        }
    }

    private onError(/*message*/) {
        this.isOpening = false;
    }

    private onOpen() {
        this.isOpening = false;
        this.isOpened = true;
        if (this.reconnectTimer) {
            clearTimeout(this.reconnectTimer);
            this.reconnectTimer = null;
        }
        if (this.isReconnect) {
            this.isReconnect = false;
            for (let r = 0; r < this.reconnectSubscribers.length; r++) {
                const reconnectSubscriber = this.reconnectSubscribers[r];
                if (reconnectSubscriber.onReconnected) {
                    reconnectSubscriber.onReconnected();
                }
                this.subscribe(reconnectSubscriber);
            }
            this.reconnectSubscribers = [];
        } else {
            this.publishCommands();
        }
    }

    private onClose() {
        this.isOpening = false;
        this.isOpened = false;
        if (this.isActive) {
            if (!this.isReconnect) {
                this.reconnectSubscribers = [];
                for (const id of Object.keys(this.subscribers)) {
                    if (this.reconnectSubscribers.indexOf(this.subscribers[id]) === -1) {
                        this.reconnectSubscribers.push(this.subscribers[id]);
                    }
                }
                this.reset(false);
                this.isReconnect = true;
            }
            if (this.reconnectTimer) {
                clearTimeout(this.reconnectTimer);
            }
            this.reconnectTimer = setTimeout(this.tryOpenSocket, RECONNECT_INTERVAL, false);
        }
    }

    private fetchKeys(subscriptionId) {
        const command = this.commands[subscriptionId];
        if (command && command.keys && command.keys.length > 0) {
            return command.keys.split(',');
        } else {
            return [];
        }
    }

    private onMessage(message) {
        if (message.data) {
            let data;
            try {
                data = JSON.parse(message.data);
            } catch (error) {
                data = {};
            }
            if (data.subscriptionId) {
                const subscriber = this.subscribers[data.subscriptionId];
                if (subscriber && data) {
                    const keys = this.fetchKeys(data.subscriptionId);
                    if (!data.data) {
                        data.data = {};
                    }
                    for (let k = 0; k < keys.length; k++) {
                        const key = keys[k];
                        if (!data.data[key]) {
                            data.data[key] = [];
                        }
                    }
                    subscriber.onData(data, data.subscriptionId);
                }
            }
        }
        this.checkToClose();
    }

    private openSocket(token) {
        this.dataStream = new $WebSocket(this.telemetryUri + '?token=' + token, null, {reconnectIfNotNormalClose: true});
        this.dataStream.onError(this.onError.bind(this));
        this.dataStream.onOpen(this.onOpen.bind(this));
        this.dataStream.onClose(this.onClose.bind(this));
        this.dataStream.onMessage(this.onMessage.bind(this), {autoApply: false});
    }


    private async tryOpenSocket() {
        if (this.isActive) {
            if (!this.isOpened && !this.isOpening) {
                this.isOpening = true;
                if (await this.userService.isJwtTokenValid()) {
                    this.openSocket(await this.userService.getJwtToken());
                } else {
                    try {
                        await this.userService.refreshJwtToken();
                        this.openSocket(await this.userService.getJwtToken());
                    } catch (error) {
                        this.isOpening = false;
                        this.userService.broadcast('unauthenticated');
                    }
                }
            }
            if (this.socketCloseTimer) {
                clearTimeout(this.socketCloseTimer);
                this.socketCloseTimer = null;
            }
        }
    }

    private publishCommands() {
        while (this.isOpened && this.hasCommands()) {
            this.dataStream.send(this.preparePublishCommands()).toPromise().then(() => {
                this.checkToClose();
            });
        }
        this.tryOpenSocket().catch(error => {
            console.log(error);
        });
    }

    private nextCmdId() {
        this.lastCmdId++;
        return this.lastCmdId;
    }

    public subscribe(subscriber) {
        this.isActive = true;
        let cmdId;
        if (subscriber.subscriptionCommands != null) {
            for (let i = 0; i < subscriber.subscriptionCommands.length; i++) {
                const subscriptionCommand = subscriber.subscriptionCommands[i];
                cmdId = this.nextCmdId();
                this.subscribers[cmdId] = subscriber;
                subscriptionCommand.cmdId = cmdId;
                this.commands[cmdId] = subscriptionCommand;
                if (subscriber.type === TypesConstant.dataKeyType.timeseries) {
                    this.cmdsWrapper.tsSubCmds.push(subscriptionCommand);
                } else if (subscriber.type === TypesConstant.dataKeyType.attribute) {
                    this.cmdsWrapper.attrSubCmds.push(subscriptionCommand);
                }
            }
        }
        if (subscriber.historyCommands != null) {
            for (let i = 0; i < subscriber.historyCommands.length; i++) {
                const historyCommand = subscriber.historyCommands[i];
                cmdId = this.nextCmdId();
                this.subscribers[cmdId] = subscriber;
                historyCommand.cmdId = cmdId;
                this.commands[cmdId] = historyCommand;
                this.cmdsWrapper.historyCmds.push(historyCommand);
            }
        }
        this.subscribersCount++;
        this.publishCommands();
    }

    public unsubscribe(subscriber) {
        if (this.isActive) {
            let cmdId = null;
            if (subscriber.subscriptionCommands) {
                for (let i = 0; i < subscriber.subscriptionCommands.length; i++) {
                    const subscriptionCommand = subscriber.subscriptionCommands[i];
                    subscriptionCommand.unsubscribe = true;
                    if (subscriber.type === TypesConstant.dataKeyType.timeseries) {
                        this.cmdsWrapper.tsSubCmds.push(subscriptionCommand);
                    } else if (subscriber.type === TypesConstant.dataKeyType.attribute) {
                        this.cmdsWrapper.attrSubCmds.push(subscriptionCommand);
                    }
                    cmdId = subscriptionCommand.cmdId;
                    if (cmdId) {
                        if (this.subscribers[cmdId]) {
                            delete this.subscribers[cmdId];
                        }
                        if (this.commands[cmdId]) {
                            delete this.commands[cmdId];
                        }
                    }
                }
            }
            if (subscriber.historyCommands) {
                for (let i = 0; i < subscriber.historyCommands.length; i++) {
                    const historyCommand = subscriber.historyCommands[i];
                    cmdId = historyCommand.cmdId;
                    if (cmdId) {
                        if (this.subscribers[cmdId]) {
                            delete this.subscribers[cmdId];
                        }
                        if (this.commands[cmdId]) {
                            delete this.commands[cmdId];
                        }
                    }
                }
            }
            const index = this.reconnectSubscribers.indexOf(subscriber);
            if (index > -1) {
                this.reconnectSubscribers.splice(index, 1);
            }
            this.subscribersCount--;
            this.publishCommands();
        }
    }
}
