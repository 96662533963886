import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs/index';
import {StorageService} from '../common/storage.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from '@angular/common/http';
import { map, filter } from 'rxjs/operators';

const jwtHelper = new JwtHelperService();

interface UserEvent {
    key: any;
    data?: any;
}

export interface User {
    authority: string;
    customerId: string;
    enabled: boolean;
    exp: number;
    iat: number;
    isPublic: boolean;
    iss: string;
    scopes: Array<string>;
    sub: string;
    tenantId: string;
    userId: string;
}

export interface TokenData {
    token: string;
    refreshToken: string;
}

export interface UserDetail {
    additionalInfo: any;
    authority: string;
    createdTime: number;
    customerId: {
        entityType: string;
        id: string;
    };
    email: string;
    firstName: string;
    id: {
        entityType: string;
        id: string;
    };
    lastName: string;
    name: string;
    tenantId: {
        entityType: string;
        id: string;
    };
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private userEvent: Subject<UserEvent>;
    private currentUser: User = null;
    private currentUserDetails: UserDetail = null;
    private userLoaded = false;
    private refreshTokenQueue = [];

    constructor(private storage: StorageService, private http: HttpClient) {
        this.userEvent = new Subject<UserEvent>();

        this.reloadUser().catch(error => {
            console.log(error);
        });
    }

    public async reloadUser(): Promise<void> {
        this.userLoaded = false;
        try {
            await this.loadUser(true);
            this.notifyUserLoaded();
        } catch (error) {
            this.notifyUserLoaded();
        }
    }

    private notifyUserLoaded(): void {
        if (!this.userLoaded) {
            this.userLoaded = true;
            this.broadcast('userLoaded');
        }
    }

    private async loadUser(doTokenRefresh): Promise<void> {
        if (!this.currentUser) {
            return this.proceedJwtTokenValidate(doTokenRefresh);
        }
    }

    private async proceedJwtTokenValidate(doTokenRefresh: boolean): Promise<void> {
        await this.validateJwtToken(doTokenRefresh);
        const jwtToken = await this.storage.get('jwt_token');
        this.currentUser = jwtHelper.decodeToken(jwtToken);

        if (this.currentUser && this.currentUser.scopes && this.currentUser.scopes.length > 0) {
            this.currentUser.authority = this.currentUser.scopes[0];
        } else if (this.currentUser) {
            this.currentUser.authority = 'ANONYMOUS';
        }

        if (this.currentUser.userId) {
            try {
                this.currentUserDetails = await this.getUser(this.currentUser.userId, true);
            } catch (error) {
                console.log('error', error);
                await this.logout();
                throw new Error();
            }
        } else {
            throw new Error();
        }
    }

    public async logout(): Promise<void> {
        this.userLoaded = false;
        await this.clearJwtToken(true);
    }

    public async clearJwtToken(doLogout: boolean): Promise<void> {
        await this.setUserFromJwtToken(null, null, true, doLogout);
    }

    public async setUserFromJwtToken(jwtToken: string, refreshToken: string, notify: boolean = false,
                                     doLogout: boolean = false): Promise<void> {
        this.currentUser = null;
        this.currentUserDetails = null;

        if (!jwtToken) {
            await this.clearTokenData();
            if (notify) {
                this.broadcast('unauthenticated', doLogout);
            }
        } else {
            await this.updateAndValidateToken(jwtToken, 'jwt_token', true);
            await this.updateAndValidateToken(refreshToken, 'refresh_token', true);
            if (notify) {
                try {
                    await this.loadUser(false);
                    this.userLoaded = true;
                    this.broadcast('authenticated');
                } catch (error) {
                    this.broadcast('unauthenticated');
                }
            } else {
                try {
                    await this.loadUser(false);
                    this.userLoaded = true;
                } catch (error) {
                    console.log('setUserFromJwtToken: unauthenticated');
                }
            }
        }
    }

    private async updateAndValidateToken(token: string, prefix: string, notify: boolean): Promise<void> {
        let valid = false;
        const tokenData = jwtHelper.decodeToken(token);
        const issuedAt = tokenData.iat;
        const expTime = tokenData.exp;
        if (issuedAt && expTime) {
            const ttl = expTime - issuedAt;
            if (ttl > 0) {
                const clientExpiration = new Date().valueOf() + ttl * 1000;
                await this.storage.set(prefix, token);
                await this.storage.set(prefix + '_expiration', clientExpiration);
                valid = true;
            }
        }
        if (!valid && notify) {
            this.broadcast('unauthenticated');
        }
    }

    private async clearTokenData(): Promise<void> {
        await this.storage.remove('jwt_token');
        await this.storage.remove('jwt_token_expiration');
        await this.storage.remove('refresh_token');
        await this.storage.remove('refresh_token_expiration');
    }

    public broadcast(key: any, data?: any): void {
        this.userEvent.next({key, data});
    }

    public async validateJwtToken(doRefresh: boolean): Promise<any> {
        if (!(await this.isTokenValid('jwt_token'))) {
            if (doRefresh) {
                return this.refreshJwtToken();
            } else {
                await this.clearJwtToken(false);
                throw new Error();
            }
        } else {
            return;
        }
    }

    private async isTokenValid(prefix: string): Promise<boolean> {
        const clientExpiration: number = await this.storage.get(prefix + '_expiration');
        const now = new Date().valueOf();

        return clientExpiration && clientExpiration > now;
    }

    public async refreshJwtToken(): Promise<TokenData> {
        let deferred;
        const promise = new Promise((resolve, reject) => {
            deferred = {resolve, reject};
        });

        this.refreshTokenQueue.push(deferred);
        if (this.refreshTokenQueue.length === 1) {
            const refreshToken = await this.storage.get('refresh_token');
            const refreshTokenValid = await this.isTokenValid('refresh_token');

            await this.setUserFromJwtToken(null, null, false, false);
            if (!refreshTokenValid) {
                this.rejectRefreshTokenQueue('access.refresh-token-expired');
            } else {
                const refreshTokenRequest = {
                    refreshToken: refreshToken
                };
                try {
                    const response: TokenData = <TokenData> await this.http.post('/api/auth/token', refreshTokenRequest).toPromise();
                    const token: string = response.token;
                    await this.setUserFromJwtToken(token, response.refreshToken, false);
                    this.resolveRefreshTokenQueue(response);
                } catch (error) {
                    await this.clearJwtToken(false);
                    this.rejectRefreshTokenQueue('access.refresh-token-failed');
                }

            }
        }

        return <Promise<TokenData>> promise;
    }

    private resolveRefreshTokenQueue(data: TokenData): void {
        for (let q = 0; q < this.refreshTokenQueue.length; q++) {
            this.refreshTokenQueue[q].resolve(data);
        }
        this.refreshTokenQueue = [];
    }

    private rejectRefreshTokenQueue(message): void {
        for (let q = 0; q < this.refreshTokenQueue.length; q++) {
            this.refreshTokenQueue[q].reject(message);
        }
        this.refreshTokenQueue = [];
    }

    public async getUser(userId: string, ignoreErrors: boolean): Promise<UserDetail> {
        const url = 'https://connexthings.io/api/user/' + userId;
        return <Promise<UserDetail>>this.http.get(url).toPromise();
    }

    public isUserLoaded(): boolean {
        return this.userLoaded;
    }

    public isAuthenticated(): Promise<string> {
        return this.storage.get('jwt_token');
    }

    public getCurrentUserDetail(): UserDetail {
        return this.currentUserDetails;
    }

    public on<T>(key: any): Observable<T> {
        return this.userEvent.asObservable().pipe(
            filter(event => event.key === key),
            map(event => <T>event.data)
        );
    }

    public async setAuthorizationRequestHeader(request): Promise<string> {
        const jwtToken = await this.storage.get('jwt_token');
        if (jwtToken) {
            request.headers = request.headers.set('X-Authorization', 'Bearer ' + jwtToken);
        }
        return jwtToken;

    }

    public isJwtTokenValid(): Promise<boolean> {
        return this.isTokenValid('jwt_token');
    }

    public getJwtToken(): Promise<string> {
        return this.storage.get('jwt_token');
    }
}
