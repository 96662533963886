import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ControlPumpPage } from './control-pump/control-pump.page';
import { ManagePumpPage } from './manage-pump/manage-pump.page';
import { MainMenuPage } from './main-menu/main-menu.page';
import { LoginPage } from './login/login.page';
import {AuthGuardService} from './common/auth-guard.service';
import {LoginAuthGuardService} from './common/login-auth-guard.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        canActivate: [LoginAuthGuardService],
        component: LoginPage,
        data: {label: 'Login'}
    },
    {
        path: 'main-menu',
        canActivate: [AuthGuardService],
        component: MainMenuPage,
        data: {label: 'Main Menu'},
        children: [
            {
                path: '',
                redirectTo: 'control-pump',
                pathMatch: 'full'
            },
            {
                path: 'control-pump',
                component: ControlPumpPage,
                data: {label: 'Plant Irrigation'}
            },
            {
                path: 'manage-pump',
                component: ManagePumpPage,
                data: {label: 'Device Management'}
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
