import {Component, OnInit} from '@angular/core';
import {LoginService} from '../api/login.service';
import {UserService} from '../api/user.service';
import {StorageService} from '../common/storage.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

    public credential = {
        username: '',
        password: ''
    };
    public rememberMe = true;
    public error = null;

    constructor(private loginService: LoginService, private userService: UserService, private storage: StorageService) {
        // console.log(loginService);
    }

    ngOnInit() {
        this.loadCredential();
    }

    async loadCredential() {
        this.credential.username = await this.storage.get('username') || '';
        this.credential.password = await this.storage.get('password') || '';
    }

    async doLogin(credential) {
        this.error = null;
        if (!this.rememberMe) {
            await this.storage.remove('username');
            await this.storage.remove('password');
        }

        try {
            const response = await this.loginService.login(credential);

            if (response) {
                // console.log(response);
                await this.userService.setUserFromJwtToken(response.token, response.refreshToken, true);
            } else {
                throw new Error('login failed');
            }

            if (this.rememberMe) {
                this.storage.set('username', credential.username);
                this.storage.set('password', credential.password);
            }
            console.log('login Success');
        } catch (error) {
            console.log('login Error', error);
            this.error = error;
            setTimeout(() => {
                this.error = null;
            }, 2000);
        }

        this.loginService.login(credential).then((data) => {
            // console.log('Login: ', data);
        }).catch((error) => {
            console.log('Login Error: ', error);
        });
    }

}
