import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TypesConstant {
    public static serverErrorCode = {
        general: 2,
        authentication: 10,
        jwtTokenExpired: 11,
        permissionDenied: 20,
        invalidArguments: 30,
        badRequestParams: 31,
        itemNotFound: 32
    };
    public static entryPoints = {
        login: 'https://connexthings.io/api/auth/login',
        tokenRefresh: 'https://connexthings.io/api/auth/token',
        nonTokenBased: 'https://connexthings.io/api/noauth'
    };
    public static id = {
        nullUid: '13814000-1dd2-11b2-8080-808080808080',
    };
    public static aggregation = {
        min: {
            value: 'MIN',
            name: 'aggregation.min'
        },
        max: {
            value: 'MAX',
            name: 'aggregation.max'
        },
        avg: {
            value: 'AVG',
            name: 'aggregation.avg'
        },
        sum: {
            value: 'SUM',
            name: 'aggregation.sum'
        },
        count: {
            value: 'COUNT',
            name: 'aggregation.count'
        },
        none: {
            value: 'NONE',
            name: 'aggregation.none'
        }
    };
    public static alarmFields = {
        createdTime: {
            keyName: 'createdTime',
            value: 'createdTime',
            name: 'alarm.created-time',
            time: true
        },
        startTime: {
            keyName: 'startTime',
            value: 'startTs',
            name: 'alarm.start-time',
            time: true
        },
        endTime: {
            keyName: 'endTime',
            value: 'endTs',
            name: 'alarm.end-time',
            time: true
        },
        ackTime: {
            keyName: 'ackTime',
            value: 'ackTs',
            name: 'alarm.ack-time',
            time: true
        },
        clearTime: {
            keyName: 'clearTime',
            value: 'clearTs',
            name: 'alarm.clear-time',
            time: true
        },
        originator: {
            keyName: 'originator',
            value: 'originatorName',
            name: 'alarm.originator'
        },
        originatorType: {
            keyName: 'originatorType',
            value: 'originator.entityType',
            name: 'alarm.originator-type'
        },
        type: {
            keyName: 'type',
            value: 'type',
            name: 'alarm.type'
        },
        severity: {
            keyName: 'severity',
            value: 'severity',
            name: 'alarm.severity'
        },
        status: {
            keyName: 'status',
            value: 'status',
            name: 'alarm.status'
        }
    };
    public static alarmStatus = {
        activeUnack: 'ACTIVE_UNACK',
        activeAck: 'ACTIVE_ACK',
        clearedUnack: 'CLEARED_UNACK',
        clearedAck: 'CLEARED_ACK'
    };
    public static alarmSearchStatus = {
        any: 'ANY',
        active: 'ACTIVE',
        cleared: 'CLEARED',
        ack: 'ACK',
        unack: 'UNACK'
    };
    public static alarmSeverity = {
        'CRITICAL': {
            name: 'alarm.severity-critical',
            class: 'tb-critical',
            color: 'red'
        },
        'MAJOR': {
            name: 'alarm.severity-major',
            class: 'tb-major',
            color: 'orange'
        },
        'MINOR': {
            name: 'alarm.severity-minor',
            class: 'tb-minor',
            color: '#ffca3d'
        },
        'WARNING': {
            name: 'alarm.severity-warning',
            class: 'tb-warning',
            color: '#abab00'
        },
        'INDETERMINATE': {
            name: 'alarm.severity-indeterminate',
            class: 'tb-indeterminate',
            color: 'green'
        }
    };
    public static auditLogActionType = {
        'ADDED': {
            name: 'audit-log.type-added'
        },
        'DELETED': {
            name: 'audit-log.type-deleted'
        },
        'UPDATED': {
            name: 'audit-log.type-updated'
        },
        'ATTRIBUTES_UPDATED': {
            name: 'audit-log.type-attributes-updated'
        },
        'ATTRIBUTES_DELETED': {
            name: 'audit-log.type-attributes-deleted'
        },
        'RPC_CALL': {
            name: 'audit-log.type-rpc-call'
        },
        'CREDENTIALS_UPDATED': {
            name: 'audit-log.type-credentials-updated'
        },
        'ASSIGNED_TO_CUSTOMER': {
            name: 'audit-log.type-assigned-to-customer'
        },
        'UNASSIGNED_FROM_CUSTOMER': {
            name: 'audit-log.type-unassigned-from-customer'
        },
        'ACTIVATED': {
            name: 'audit-log.type-activated'
        },
        'SUSPENDED': {
            name: 'audit-log.type-suspended'
        },
        'CREDENTIALS_READ': {
            name: 'audit-log.type-credentials-read'
        },
        'ATTRIBUTES_READ': {
            name: 'audit-log.type-attributes-read'
        }
    };
    public static auditLogActionStatus = {
        'SUCCESS': {
            value: 'SUCCESS',
            name: 'audit-log.status-success'
        },
        'FAILURE': {
            value: 'FAILURE',
            name: 'audit-log.status-failure'
        }
    };
    public static auditLogMode = {
        tenant: 'tenant',
        entity: 'entity',
        user: 'user',
        customer: 'customer'
    };
    public static aliasFilterType = {
        singleEntity: {
            value: 'singleEntity',
            name: 'alias.filter-type-single-entity'
        },
        entityList: {
            value: 'entityList',
            name: 'alias.filter-type-entity-list'
        },
        entityName: {
            value: 'entityName',
            name: 'alias.filter-type-entity-name'
        },
        stateEntity: {
            value: 'stateEntity',
            name: 'alias.filter-type-state-entity'
        },
        assetType: {
            value: 'assetType',
            name: 'alias.filter-type-asset-type'
        },
        deviceType: {
            value: 'deviceType',
            name: 'alias.filter-type-device-type'
        },
        relationsQuery: {
            value: 'relationsQuery',
            name: 'alias.filter-type-relations-query'
        },
        assetSearchQuery: {
            value: 'assetSearchQuery',
            name: 'alias.filter-type-asset-search-query'
        },
        deviceSearchQuery: {
            value: 'deviceSearchQuery',
            name: 'alias.filter-type-device-search-query'
        }
    };
    public static position = {
        top: {
            value: 'top',
            name: 'position.top'
        },
        bottom: {
            value: 'bottom',
            name: 'position.bottom'
        },
        left: {
            value: 'left',
            name: 'position.left'
        },
        right: {
            value: 'right',
            name: 'position.right'
        }
    };
    public static datasourceType = {
        function: 'function',
        entity: 'entity'
    };
    public static dataKeyType = {
        timeseries: 'timeseries',
        attribute: 'attribute',
        function: 'function',
        alarm: 'alarm'
    };
    public static componentType = {
        filter: 'FILTER',
        processor: 'PROCESSOR',
        action: 'ACTION',
        plugin: 'PLUGIN'
    };
    public static entityType = {
        device: 'DEVICE',
        asset: 'ASSET',
        rule: 'RULE',
        plugin: 'PLUGIN',
        tenant: 'TENANT',
        customer: 'CUSTOMER',
        user: 'USER',
        dashboard: 'DASHBOARD',
        alarm: 'ALARM'
    };
    public static aliasEntityType = {
        current_customer: 'CURRENT_CUSTOMER'
    };
    public static entityTypeTranslations = {
        'DEVICE': {
            type: 'entity.type-device',
            typePlural: 'entity.type-devices',
            list: 'entity.list-of-devices',
            nameStartsWith: 'entity.device-name-starts-with'
        },
        'ASSET': {
            type: 'entity.type-asset',
            typePlural: 'entity.type-assets',
            list: 'entity.list-of-assets',
            nameStartsWith: 'entity.asset-name-starts-with'
        },
        'RULE': {
            type: 'entity.type-rule',
            typePlural: 'entity.type-rules',
            list: 'entity.list-of-rules',
            nameStartsWith: 'entity.rule-name-starts-with'
        },
        'PLUGIN': {
            type: 'entity.type-plugin',
            typePlural: 'entity.type-plugins',
            list: 'entity.list-of-plugins',
            nameStartsWith: 'entity.plugin-name-starts-with'
        },
        'TENANT': {
            type: 'entity.type-tenant',
            typePlural: 'entity.type-tenants',
            list: 'entity.list-of-tenants',
            nameStartsWith: 'entity.tenant-name-starts-with'
        },
        'CUSTOMER': {
            type: 'entity.type-customer',
            typePlural: 'entity.type-customers',
            list: 'entity.list-of-customers',
            nameStartsWith: 'entity.customer-name-starts-with'
        },
        'USER': {
            type: 'entity.type-user',
            typePlural: 'entity.type-users',
            list: 'entity.list-of-users',
            nameStartsWith: 'entity.user-name-starts-with'
        },
        'DASHBOARD': {
            type: 'entity.type-dashboard',
            typePlural: 'entity.type-dashboards',
            list: 'entity.list-of-dashboards',
            nameStartsWith: 'entity.dashboard-name-starts-with'
        },
        'ALARM': {
            type: 'entity.type-alarm',
            typePlural: 'entity.type-alarms',
            list: 'entity.list-of-alarms',
            nameStartsWith: 'entity.alarm-name-starts-with'
        },
        'CURRENT_CUSTOMER': {
            type: 'entity.type-current-customer',
            list: 'entity.type-current-customer'
        }
    };
    public static entitySearchDirection = {
        from: 'FROM',
        to: 'TO'
    };
    public static entityRelationType = {
        contains: 'Contains',
        manages: 'Manages'
    };
    public static eventType = {
        error: {
            value: 'ERROR',
            name: 'event.type-error'
        },
        lcEvent: {
            value: 'LC_EVENT',
            name: 'event.type-lc-event'
        },
        stats: {
            value: 'STATS',
            name: 'event.type-stats'
        }
    };
    public static extensionType = {
        http: 'HTTP',
        mqtt: 'MQTT',
        opc: 'OPC UA'
    };
    public static extensionValueType = {
        string: 'value.string',
        long: 'value.long',
        double: 'value.double',
        boolean: 'value.boolean'
    };
    public static extensionTransformerType = {
        toDouble: 'extension.to-double',
        custom: 'extension.custom'
    };
    public static mqttConverterTypes = {
        json: 'extension.converter-json',
        custom: 'extension.custom'
    };
    public static mqttCredentialTypes = {
        anonymous: {
            value: 'anonymous',
            name: 'extension.anonymous'
        },
        basic: {
            value: 'basic',
            name: 'extension.basic'
        },
        pem: {
            value: 'cert.PEM',
            name: 'extension.pem'
        }
    };
    public static extensionOpcSecurityTypes = {
        Basic128Rsa15: 'Basic128Rsa15',
        Basic256: 'Basic256',
        Basic256Sha256: 'Basic256Sha256',
        None: 'None'
    };
    public static extensionIdentityType = {
        anonymous: 'extension.anonymous',
        username: 'extension.username'
    };
    public static extensionKeystoreType = {
        PKCS12: 'PKCS12',
        JKS: 'JKS'
    };
    public static latestTelemetry = {
        value: 'LATEST_TELEMETRY',
        name: 'attribute.scope-latest-telemetry',
        clientSide: true
    };
    public static attributesScope = {
        client: {
            value: 'CLIENT_SCOPE',
            name: 'attribute.scope-client',
            clientSide: true
        },
        server: {
            value: 'SERVER_SCOPE',
            name: 'attribute.scope-server',
            clientSide: false
        },
        shared: {
            value: 'SHARED_SCOPE',
            name: 'attribute.scope-shared',
            clientSide: false
        }
    };
    public static valueType = {
        string: {
            value: 'string',
            name: 'value.string',
            icon: 'mdi:format-text'
        },
        integer: {
            value: 'integer',
            name: 'value.integer',
            icon: 'mdi:numeric'
        },
        double: {
            value: 'double',
            name: 'value.double',
            icon: 'mdi:numeric'
        },
        boolean: {
            value: 'boolean',
            name: 'value.boolean',
            icon: 'mdi:checkbox-marked-outline'
        }
    };
    public static widgetType = {
        timeseries: {
            value: 'timeseries',
            name: 'widget.timeseries',
            template: {
                bundleAlias: 'charts',
                alias: 'basic_timeseries'
            }
        },
        latest: {
            value: 'latest',
            name: 'widget.latest-values',
            template: {
                bundleAlias: 'cards',
                alias: 'attributes_card'
            }
        },
        rpc: {
            value: 'rpc',
            name: 'widget.rpc',
            template: {
                bundleAlias: 'gpio_widgets',
                alias: 'basic_gpio_control'
            }
        },
        alarm: {
            value: 'alarm',
            name: 'widget.alarm',
            template: {
                bundleAlias: 'alarm_widgets',
                alias: 'alarms_table'
            }
        },
        static: {
            value: 'static',
            name: 'widget.static',
            template: {
                bundleAlias: 'cards',
                alias: 'html_card'
            }
        }
    };
    public static widgetActionSources = {
        headerButton: {
            name: 'widget-action.header-button',
            value: 'headerButton',
            multiple: true
        }
    };
    public static widgetActionTypes = {
        openDashboardState: {
            name: 'widget-action.open-dashboard-state',
            value: 'openDashboardState'
        },
        updateDashboardState: {
            name: 'widget-action.update-dashboard-state',
            value: 'updateDashboardState'
        },
        openDashboard: {
            name: 'widget-action.open-dashboard',
            value: 'openDashboard'
        },
        custom: {
            name: 'widget-action.custom',
            value: 'custom'
        }
    };
    public static systemBundleAlias = {
        charts: 'charts',
        cards: 'cards'
    };
    public static translate = {
        customTranslationsPrefix: 'custom.'
    };
}
