import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TypesConstant} from '../common/types.constant';
import {TelemetryWebsocketService} from './telemetry-websocket.service';
import _ from 'lodash';

export interface AttributeData {
    key: string;
    lastUpdateTs?: number;
    value: string | number | boolean;
}

export interface AttributeDataList {
    count: number;
    data: Array<AttributeData>;
}

@Injectable({
    providedIn: 'root'
})
export class AttributeService {

    private entityAttributesSubscriptionMap = {};

    constructor(private http: HttpClient, private telemetryWebsocketService: TelemetryWebsocketService) {
    }

    public subscribeForEntityAttributes(entityType, entityId, attributeScope): string {
        const subscriptionId = entityType + entityId + attributeScope;
        let entityAttributesSubscription = this.entityAttributesSubscriptionMap[subscriptionId];
        if (!entityAttributesSubscription) {
            const subscriptionCommand = {
                entityType: entityType,
                entityId: entityId,
                scope: attributeScope
            };

            const type = attributeScope === TypesConstant.latestTelemetry.value ?
                TypesConstant.dataKeyType.timeseries : TypesConstant.dataKeyType.attribute;

            const subscriber = {
                subscriptionCommands: [subscriptionCommand],
                type: type,
                onData: data => {
                    if (data.data) {
                        this.onSubscriptionData(data.data, subscriptionId);
                    }
                }
            };
            entityAttributesSubscription = {
                subscriber: subscriber,
                attributes: null
            };
            this.entityAttributesSubscriptionMap[subscriptionId] = entityAttributesSubscription;
            this.telemetryWebsocketService.subscribe(subscriber);
        }
        return subscriptionId;
    }

    private onSubscriptionData(data, subscriptionId) {
        const entityAttributesSubscription = this.entityAttributesSubscriptionMap[subscriptionId];
        if (entityAttributesSubscription) {
            if (!entityAttributesSubscription.attributes) {
                entityAttributesSubscription.attributes = [];
                entityAttributesSubscription.keys = {};
            }
            const attributes = entityAttributesSubscription.attributes;
            const keys = entityAttributesSubscription.keys;
            for (const key of Object.keys(data)) {
                let index = keys[key];
                let attribute;
                if (index > -1) {
                    attribute = attributes[index];
                } else {
                    attribute = {
                        key: key
                    };
                    index = attributes.push(attribute) - 1;
                    keys[key] = index;
                }
                const attrData = data[key][0];
                attribute.lastUpdateTs = attrData[0];
                attribute.value = attrData[1];
            }
            if (entityAttributesSubscription.subscriptionCallback) {
                entityAttributesSubscription.subscriptionCallback(attributes);
            }
        }
    }

    public getEntityAttributes(entityType: string, entityId: string, attributeScope: string, query, successCallback) {
        let deferred;
        const promise = new Promise((resolve, reject) => {
            deferred = {resolve, reject};
        });

        const subscriptionId: string = entityType + entityId + attributeScope;
        const eas = this.entityAttributesSubscriptionMap[subscriptionId];
        if (eas) {
            if (eas.attributes) {
                this.processAttributes(eas.attributes, query, deferred, successCallback);
                eas.subscriptionCallback = (attributes) => {
                    this.processAttributes(attributes, query, null, successCallback, true, true);
                };
            } else {
                eas.subscriptionCallback = (attributes) => {
                    this.processAttributes(attributes, query, deferred, successCallback, false, true);
                    eas.subscriptionCallback = (attrs) => {
                        this.processAttributes(attrs, query, null, successCallback, true, true);
                    };
                };
            }
        } else {
            const url = '/api/plugins/telemetry/' + entityType + '/' + entityId + '/values/attributes/' + attributeScope;
            this.http.get(url).toPromise().then((response: Array<AttributeData>) => {
                this.processAttributes(response, query, deferred, successCallback);
            }, (error) => {
                // todo remove log
                console.log(error);
                deferred.reject();
            });
        }
        return promise;
    }

    private processAttributes(attributes: Array<AttributeData>, query, deferred, successCallback, update?, apply?): void {
        attributes = _.orderBy(attributes, query.order);
        if (query.search != null) {
            attributes = _.filter(attributes, {key: query.search});
        }
        const responseData: AttributeDataList = {
            count: attributes.length,
            data: []
        };
        const startIndex = query.limit * (query.page - 1);
        responseData.data = attributes.slice(startIndex, startIndex + query.limit);
        successCallback(responseData, update, apply);
        if (deferred) {
            deferred.resolve();
        }
    }

    public unsubscribeForEntityAttributes(subscriptionId): void {
        const entityAttributesSubscription = this.entityAttributesSubscriptionMap[subscriptionId];
        if (entityAttributesSubscription) {
            this.telemetryWebsocketService.unsubscribe(entityAttributesSubscription.subscriber);
            delete this.entityAttributesSubscriptionMap[subscriptionId];
        }
    }

    public async saveEntityAttributes(entityType: string, entityId: string, attributeScope: string,
                                      attribute): Promise<any> {


        if (Object.keys(attribute).length) {
            const url = 'https://connexthings.io/api/plugins/telemetry/' + entityType + '/' + entityId + '/' + attributeScope;
            return await this.http.post(url, attribute, {responseType: 'text'}).toPromise();
        }
    }
}
