import {Component, OnInit} from '@angular/core';
import {UserDetail, UserService} from '../api/user.service';
import {StorageService} from '../common/storage.service';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.page.html',
    styleUrls: ['./main-menu.page.scss'],
})
export class MainMenuPage implements OnInit {

    public appPages = [
        {
            title: 'Plant Irrigation',
            url: './control-pump',
            ionicon: 'switch',
            flaticon: null,
            fontSize: 38,
            color: 'primary'
        },
        // {
        //     title: 'Device Management',
        //     url: './manage-pump',
        //     ionicon: 'add-circle',
        //     flaticon: null,
        //     fontSize: 32,
        //     color: 'primary'
        // }
    ];

    public userDetail: UserDetail = <UserDetail>{};

    constructor(private userService: UserService, private storage: StorageService) {
        this.userDetail = this.userService.getCurrentUserDetail();
    }

    ngOnInit() {
    }

    async doLogout() {
        this.userService.logout();
        await this.storage.remove('username');
        await this.storage.remove('password');
    }

}
