import {Component, OnDestroy} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ConfigPumpComponent} from '../config-pump/config-pump.component';
import {DeviceService} from '../api/device.service';
import {TypesConstant} from '../common/types.constant';
import {AttributeData, AttributeService} from '../api/attribute.service';

@Component({
    selector: 'app-control-pump',
    templateUrl: 'control-pump.page.html',
    styleUrls: ['control-pump.page.scss'],
})
export class ControlPumpPage implements OnDestroy {
    devices = [];

    private subscriptionIdAttributeList: Array<string> = [];

    constructor(private modalController: ModalController, private deviceService: DeviceService,
                private attributeService: AttributeService) {
        this.getDevice();
    }

    ngOnDestroy() {
        this.subscriptionIdAttributeList.forEach((subscriptionId) => {
            this.attributeService.unsubscribeForEntityAttributes(subscriptionId);
        });

        this.subscriptionIdAttributeList = [];
    }

    async getDevice() {
        try {
            const devices: any = await this.deviceService.getUserDevices('control-2ch');
            if (devices && devices.data) {
                devices.data.forEach((device) => {
                    device.reportedState = {};
                    device.desiredState = {};
                    device.isOffline = false;
                    device.desiredStateLoading = false;
                    device.reportedStateLoading = false;
                    device.deisredStateError = false;
                    this.getAndSubscribeReportState(device, 10);
                });
            }

            this.devices = devices.data;
        } catch (error) {
            console.log('error', error);
        }
    }

    public isDeviceOffline(device) {
        const now = new Date();
        return ((now.getTime() - device.lastUpdate) > 10 * 60 * 1000); // 10 minutes
    }

    private getAndSubscribeReportState(device, limit) {
        const query = {limit: limit, order: 'key', page: 1, search: null};

        this.subscriptionIdAttributeList.push(this.attributeService.subscribeForEntityAttributes(TypesConstant.entityType.device,
            device.id.id, TypesConstant.attributesScope.client.value));

        return this.attributeService.getEntityAttributes(TypesConstant.entityType.device, device.id.id,
            TypesConstant.attributesScope.client.value, query, (attributes) => {

                // attributes.data.reduce((lastValue, currentValue) => {
                //     if (currentValue.key === 'onOff') {
                //         lastValue.onOff = (currentValue.value === 'true');
                //     } else if (currentValue.key === 'onTime') {
                //         lastValue.onTime = parseInt(currentValue.value, 10);
                //     }
                //     return lastValue;
                // }, device.reportedState);
                device.reportedStateLoading = false;

                attributes.data.forEach((attr) => {
                    // console.log('attr', attr, device);
                    if (attr.key === 'onOff') {
                        device.reportedState.onOff = (attr.value === 'true');
                        device.desiredState.onOff = device.reportedState.onOff;
                        device.lastUpdate = attr.lastUpdateTs;
                    } else if (attr.key === 'onTime') {
                        device.reportedState.onTime = parseInt(attr.value, 10);
                        device.desiredState.onTime = device.reportedState.onTime;
                    }
                });
            });
    }

    async presentConfigPumpModal(device) {
        let modal;
        try {
            modal = await this.modalController.create({
                component: ConfigPumpComponent,
                componentProps: {
                    timerValue: device.desiredState.onTime,
                    deviceName: device.name
                }
            });
        } catch (error) {
            console.log(error);
        }

        modal.onDidDismiss().then((data) => {
            if (data.data !== device.desiredState.onTime) {
                device.desiredState.onTime = data.data;
                try {
                    this.attributeService.saveEntityAttributes(TypesConstant.entityType.device, device.id.id,
                        TypesConstant.attributesScope.shared.value, {onTime: device.desiredState.onTime});
                } catch (error) {
                    console.log('Save Attribute Error: ', error);
                }
            }
        });

        return await modal.present();
    }

    async controlDevice(device) {
        if (device.desiredState.onOff === device.reportedState.onOff) {
            return;
        }

        // console.log('desireState', device);
        device.reportedStateLoading = true;
        device.deisredStateError = false;
        const timeoutHandle = setTimeout(() => {
            device.desiredStateLoading = true;
        }, 200);

        try {
            await this.attributeService.saveEntityAttributes(TypesConstant.entityType.device, device.id.id,
                TypesConstant.attributesScope.shared.value, {onOff: device.desiredState.onOff});
            device.desiredStateLoading = false;
            clearTimeout(timeoutHandle);
        } catch (error) {
            console.log('Save Attribute Error: ', error);
            clearTimeout(timeoutHandle);
            device.desiredStateLoading = false;
            device.deisredStateError = true;
            device.desiredState.onOff = !device.desiredState.onOff;
        }
    }
}
