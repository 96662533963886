import {Component} from '@angular/core';
import {NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {UserService, UserDetail} from './api/user.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {

    public userDetail: UserDetail;
    private userLoadedHandle;

    constructor(private platform: Platform,
                private splashScreen: SplashScreen,
                private statusBar: StatusBar, private userService: UserService, private navController: NavController) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });

        this.initializeUser().catch(error => console.log(error));
        this.initWatchers();
    }

    private initWatchers(): void {
        this.userService.on('unauthenticated').subscribe((doLogout) => {
            console.log('unauthenticated');
            this.navController.navigateRoot('login');
        });

        this.userService.on('authenticated').subscribe(() => {
            console.log('authenticated');
            this.userDetail = this.userService.getCurrentUserDetail();
            this.navController.navigateRoot('main-menu');
        });

        this.userService.on('forbidden').subscribe(() => {
            console.log('forbidden');
        });
    }

    private async initializeUser(): Promise<void> {
        if (this.userService.isUserLoaded() === true) {
            if (await this.userService.isAuthenticated()) {
                this.userDetail = this.userService.getCurrentUserDetail();
                this.navController.navigateRoot('main-menu');
            } else {
                this.navController.navigateRoot('login');
            }
        } else {
            this.waitForUserLoaded();
        }
    }

    private waitForUserLoaded() {
        if (this.userLoadedHandle && this.userLoadedHandle.unsubscribe) {
            this.userLoadedHandle.unsubscribe();
        }

        this.userLoadedHandle = this.userService.on('userLoaded').subscribe(() => {
            this.userLoadedHandle.unsubscribe();
            this.initializeUser().catch(error => console.log(error));
        });
    }
}
