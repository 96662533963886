import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserService} from '../api/user.service';

@Injectable({
    providedIn: 'root'
})
export class LoginAuthGuardService implements CanActivate {

    constructor(public userService: UserService, private router: Router) {
    }

    canActivate(): boolean {
        const isUserLoaded = this.userService.isUserLoaded();
        if (isUserLoaded) {
            this.router.navigate(['main-menu']);
        }
        // console.log('isUserLoaded', isUserLoaded);
        return !isUserLoaded;
    }
}
