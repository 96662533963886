import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    constructor(private http: HttpClient) {
    }

    public async getUserDevices(type: string, limit: number = 100) {
        const url = `https://connexthings.io/api/tenant/devices?limit=${limit}&type=${type}`;
        // console.log('url', url);
        return this.http.get(url).toPromise();
    }
}
