import {Injectable, Injector} from '@angular/core';
import {
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs/index';

import {flatMap, tap} from 'rxjs/operators';
import {ShareService} from '../api/share.service';
import {UserService} from '../api/user.service';
import {TypesConstant} from '../common/types.constant';

@Injectable()
export class AllInterceptor implements HttpInterceptor {
    private userService;

    constructor(private shareService: ShareService, private inj: Injector) {
    }

    private internalUrlPrefixes = [
        'https://connexthings.io/api/auth/token',
        'https://connexthings.io/api/plugins/rpc'
    ];

    private isInternalUrlPrefix(url): boolean {
        for (const index in this.internalUrlPrefixes) {
            if (url.startsWith(this.internalUrlPrefixes[index])) {
                return true;
            }
        }
        return false;
    }

    private isTokenBasedAuthEntryPoint(url): boolean {
        return url.startsWith('https://connexthings.io/api/') &&
            !url.startsWith(TypesConstant.entryPoints.login) &&
            !url.startsWith(TypesConstant.entryPoints.tokenRefresh) &&
            !url.startsWith(TypesConstant.entryPoints.nonTokenBased);
    }

    private updateLoadingState(isLoading): void {
        this.shareService.loading = isLoading;
    }

    private async refreshTokenAndRetry(request): Promise<void> {
        try {
            await this.userService.refreshJwtToken();
            await this.userService.setAuthorizationRequestHeader(request);
            await this.userService.isJwtTokenValid();
        } catch (error) {
            this.userService.broadcast('unauthenticated');
        }
    }

    private modifyRequest(req: HttpRequest<any>): Observable<HttpRequest<any>> {
        return new Observable(observer => {
            const newReq = req.clone();
            if (newReq.url.startsWith('https://connexthings.io/api/')) {
                const isLoading = !this.isInternalUrlPrefix(newReq.url);
                this.updateLoadingState(isLoading);
                if (this.isTokenBasedAuthEntryPoint(newReq.url)) {
                    this.userService.setAuthorizationRequestHeader(newReq)
                        .then(jwtToken => {
                            if (!jwtToken && !this.userService.refreshTokenPending()) {
                                this.updateLoadingState(false);
                                this.userService.clearJwtToken(false);
                                return Promise.reject({
                                    data: {message: 'access.unauthorized'},
                                    status: 401,
                                    request: newReq
                                });
                            } else {
                                return this.userService.isJwtTokenValid();
                            }
                        })
                        .then(jwtTokenValid => {
                            if (!jwtTokenValid) {
                                // return Promise.reject({refreshTokenPending: true, request: newReq});
                                return this.refreshTokenAndRetry(newReq);
                            }
                        })
                        .then(() => {
                            observer.next(newReq);
                            observer.complete();
                        }, error => {
                            observer.error(error);
                        });
                } else {
                    observer.next(newReq);
                    observer.complete();
                }
            }
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.userService) {
            this.userService = this.inj.get(UserService);
        }

        return this.modifyRequest(req).pipe(flatMap(modifiedReq => {
            // console.log('request: ', modifiedReq);
            const newReq = req.clone(modifiedReq);
            return next.handle(newReq).pipe(tap(event => {
                if (event instanceof HttpResponse) {
                    // console.log('response: ', event);
                    if (event.url.startsWith('https://connexthings.io/api/')) {
                        this.updateLoadingState(false);
                    }
                }
            }));
        }));
    }
}
